import React, { FC } from 'react';

import classes from './Layout.module.scss';

import Footer from '../Footer/Footer';
import CookieConsent from '../CookieConsent/CookieConsent';

const Layout: FC = ({ children }) => {
  return (
    <main className={classes.Layout}>
      <div className={classes.MainContentContainer}>{children}</div>
      <div className={classes.FooterContainer}>
        <Footer />
      </div>
      <CookieConsent />
    </main>
  );
};

export default Layout;
