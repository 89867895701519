import React, { FC, useEffect, useState } from 'react';
import classes from './CookieConsent.module.scss';
import { Link } from 'gatsby';
import {
  CookieConsentCaption,
  FooterCaption,
  OtherLinks,
} from '../../constants';
import Button from '../Button/Button';
import { Button as BootstrapButton, Col, Row } from 'react-bootstrap';
import Cookies from 'js-cookie';

const CookieConsent: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!getCookieConsentValue()) {
      setVisible(true);
    }
  }, []);

  const cookieName = 'CREABPMCOOKIE';

  const getCookieConsentValue = (name = cookieName) => {
    return Cookies.get(name);
  };

  const accept = () => {
    setCookie(cookieName, 'true');
    setVisible(false);
  };

  const decline = () => {
    setCookie(cookieName, 'false');
    setVisible(false);
  };

  const setCookie = (cookieName: string, cookieValue: string) => {
    const cookieOptions = {
      expires: 365,
    };

    Cookies.set(cookieName, cookieValue, cookieOptions);
  };

  return visible ? (
    <div className={classes.Container}>
      <Row className={classes.ContentContainer}>
        <Col md={12} lg={8} className={classes.TextContainer}>
          {CookieConsentCaption.FIRST_PART}
          <Link to={OtherLinks.PRIVACY} className={classes.Link}>
            {FooterCaption.PRIVACY}
          </Link>
          {CookieConsentCaption.SECOND_PART}
          <Link to={OtherLinks.NOTICE} className={classes.Link}>
            {FooterCaption.NOTICE}
          </Link>
          {CookieConsentCaption.THIRD_PART}
        </Col>
        <Col md={12} lg={4} className={classes.ButtonsContainer}>
          <BootstrapButton
            onClick={() => decline()}
            className={classes.SecondaryButton}
          >
            <span>{CookieConsentCaption.DECLINE}</span>
          </BootstrapButton>
          <Button onClick={() => accept()}>
            {CookieConsentCaption.ACCEPT}
          </Button>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default CookieConsent;
