import React, { FC } from 'react';
import { navigate, PageProps } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';

import 'bootstrap/dist/css/bootstrap.css';
import '../styles/Index.scss';
import classes from './services.module.scss';

import {
  OtherLinks,
  SeoCaption,
  ServicesPageCaption,
  Socials,
} from '../constants';
import Header from '../components/Header/Header';
import Hero, { HeroType } from '../components/Hero/Hero';
import ServicesList from '../components/ServicesList/ServicesList';
import Layout from '../components/Layout/Layout';

import bizagiLogo from '../images/partners/bizagi.png';
import appianLogo from '../images/partners/appian.png';
import ultimusLogo from '../images/partners/ultimus.png';
import assessImage from '../images/services/assess.png';
import designImage from '../images/services/design.png';
import certifyImage from '../images/services/certify.png';
import buildImage from '../images/services/build.png';
import implementImage from '../images/services/implement.png';
import Button from '../components/Button/Button';
import SEO from '../components/SEO/SEO';

const Services: FC<PageProps> = (props) => {
  return (
    <Layout>
      <SEO title={SeoCaption.SERVICES} />
      <Header
        page={HeroType.SERVICES}
        {...props}
        title={ServicesPageCaption.HEADER_TITLE}
        subtitle={ServicesPageCaption.HEADER_SUBTITLE}
        description={ServicesPageCaption.HEADER_DESCRIPTION}
        descriptionRedirect={OtherLinks.CONTACT_US}
      />
      {/* First Section */}
      <Row className={classes.FirstSection}>
        <Col sm={12} md={5} className={classes.FirstLeftColumn}>
          {ServicesPageCaption.FIRST_SECTION_LEFT_COLUMN}
        </Col>
        <Col md={1} />
        <Col sm={12} md={6} className={classes.FirstRightColumn}>
          {ServicesPageCaption.FIRST_SECTION_RIGHT_COLUMN}
        </Col>
      </Row>
      {/* Second Section */}
      <Hero page={HeroType.SERVICES_SUBSECTION_1} />
      <div className={classes.SecondSection}>
        <ServicesList />
      </div>
      {/* Third Section */}
      <Hero page={HeroType.SERVICES_SUBSECTION_2} />
      <div className={classes.ThirdSection}>
        <div className={classes.ThirdSectionTitle}>
          {ServicesPageCaption.THIRD_SECTION_TITLE}
        </div>
        <div className={classes.ThirdSectionSubtitle}>
          {ServicesPageCaption.THIRD_SECTION_SUBTITLE}
        </div>
        <Row className={classes.ThirdSectionLogosContainer}>
          <Col sm={12} md={4} className={classes.ThirdSectionLeftColumn}>
            <a href={Socials.ULTIMUS} target={'_blank'} rel="noreferrer">
              <img
                src={ultimusLogo}
                alt={'Ultimus Logo'}
                className={classes.ThirdSectionLogo}
              />
            </a>
            <div className={classes.ThirdSectionLogoLabel}>
              {ServicesPageCaption.THIRD_SECTION_LOGO_1_LABEL}
            </div>
          </Col>
          <Col sm={12} md={4} className={classes.ThirdSectionLeftColumn}>
            <a href={Socials.BIZAGI} target={'_blank'} rel="noreferrer">
              <img
                src={bizagiLogo}
                alt={'Bizagi Logo'}
                className={classes.ThirdSectionLogo}
              />
            </a>
            <div className={classes.ThirdSectionLogoLabel}>
              {ServicesPageCaption.THIRD_SECTION_LOGO_2_LABEL}
            </div>
          </Col>
          <Col sm={12} md={4} className={classes.ThirdSectionLeftColumn}>
            <a href={Socials.APPIAN} target={'_blank'} rel="noreferrer">
              <img
                src={appianLogo}
                alt={'Appian Logo'}
                className={classes.ThirdSectionLogo}
              />
            </a>
            <div className={classes.ThirdSectionLogoLabel}>
              {ServicesPageCaption.THIRD_SECTION_LOGO_3_LABEL}
            </div>
          </Col>
        </Row>
      </div>
      {/* Fourth Section */}
      <div className={classes.FourthSection}>
        <Hero page={HeroType.GREY_BACKGROUND} />
        <div>
          <Row className={classes.FourthSectionTitle}>
            <Col md={12} lg={9} className="pl-0">
              {ServicesPageCaption.FOURTH_SECTION_TITLE}
            </Col>
          </Row>
          <Row className={classes.FourthSectionCards}>
            <ServiceCard
              image={assessImage}
              title={ServicesPageCaption.SERVICE_CARD_ITEM_1_TITLE}
              description={ServicesPageCaption.SERVICE_CARD_ITEM_1_DESCRIPTION}
            />
            <ServiceCard
              image={designImage}
              title={ServicesPageCaption.SERVICE_CARD_ITEM_2_TITLE}
              description={ServicesPageCaption.SERVICE_CARD_ITEM_2_DESCRIPTION}
            />
            <ServiceCard
              image={buildImage}
              title={ServicesPageCaption.SERVICE_CARD_ITEM_3_TITLE}
              description={ServicesPageCaption.SERVICE_CARD_ITEM_3_DESCRIPTION}
            />
            <ServiceCard
              image={certifyImage}
              title={ServicesPageCaption.SERVICE_CARD_ITEM_4_TITLE}
              description={ServicesPageCaption.SERVICE_CARD_ITEM_4_DESCRIPTION}
            />
            <ServiceCard
              image={implementImage}
              title={ServicesPageCaption.SERVICE_CARD_ITEM_5_TITLE}
              description={ServicesPageCaption.SERVICE_CARD_ITEM_5_DESCRIPTION}
              last
            />
          </Row>
          <div className={classes.FourthSectionDivider} />
        </div>
        <Row className={classes.FourthSectionFooter}>
          <Col lg={8} className={classes.FourthSectionFooterTitle}>
            {ServicesPageCaption.FOURTH_SECTION_FOOTER_TITLE}
          </Col>
          <Col lg={4} className={classes.FourthSectionFooterSubtitleContainer}>
            <Button onClick={() => navigate(OtherLinks.CONTACT_US)}>
              {ServicesPageCaption.FOURTH_SECTION_FOOTER_SUBTITLE}
            </Button>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

interface ServiceCardProps {
  image: string;
  title: string;
  description: string;
  last?: boolean;
}

const ServiceCard: FC<ServiceCardProps> = ({
  image,
  title,
  description,
  last,
}) => {
  return (
    <Col
      md={12}
      lg={true}
      className={classNames(classes.ServiceCardContainer, {
        [classes.Last]: last,
      })}
    >
      <div className={classes.ServiceCardImageContainer}>
        <img src={image} alt={title} className={classes.ServiceCardImage} />
      </div>
      <div className={classes.ServiceCardTextContainer}>
        <div className={classes.ServiceCardTitle}>{title}</div>
        <div className={classes.ServiceCardDescription}>{description}</div>
      </div>
    </Col>
  );
};

export default Services;
