import React, { FC } from 'react';
import { navigate } from 'gatsby';

import { Col, Row } from 'react-bootstrap';

import classes from './ServicesList.module.scss';
import { OtherLinks, ServicesPageCaption } from '../../constants';

interface ServicesListItem {
  title: string;
  description: string;
  link: string;
}

const ServiceListItem: FC<ServicesListItem> = ({
  title,
  description,
  link,
}) => {
  return (
    <Col sm={12} lg={4}>
      <div className={classes.Title}>{title}</div>
      <div className={classes.Description}>{description}</div>
      <div
        className={classes.Link}
        onClick={() => navigate(`${OtherLinks.CONTACT_US}?dropdown=${link}`)}
      >
        {link}
      </div>
    </Col>
  );
};

const ServicesList: FC = () => {
  return (
    <>
      <Row className={classes.Row}>
        <Col sm={12} lg={4}>
          <div className={classes.IntroTitle}>
            {ServicesPageCaption.SERVICE_LIST_ITEM_1_TITLE}
          </div>
          <div className={classes.IntroDescription}>
            {ServicesPageCaption.SERVICE_LIST_ITEM_1_DESCRIPTION}
          </div>
        </Col>
        <ServiceListItem
          title={ServicesPageCaption.SERVICE_LIST_ITEM_2_TITLE}
          description={ServicesPageCaption.SERVICE_LIST_ITEM_2_DESCRIPTION}
          link={ServicesPageCaption.SERVICE_LIST_ITEM_2_LINK}
        />
        <ServiceListItem
          title={ServicesPageCaption.SERVICE_LIST_ITEM_3_TITLE}
          description={ServicesPageCaption.SERVICE_LIST_ITEM_3_DESCRIPTION}
          link={ServicesPageCaption.SERVICE_LIST_ITEM_3_LINK}
        />
      </Row>
      <Row className={classes.Row}>
        <ServiceListItem
          title={ServicesPageCaption.SERVICE_LIST_ITEM_4_TITLE}
          description={ServicesPageCaption.SERVICE_LIST_ITEM_4_DESCRIPTION}
          link={ServicesPageCaption.SERVICE_LIST_ITEM_4_LINK}
        />
        <ServiceListItem
          title={ServicesPageCaption.SERVICE_LIST_ITEM_5_TITLE}
          description={ServicesPageCaption.SERVICE_LIST_ITEM_5_DESCRIPTION}
          link={ServicesPageCaption.SERVICE_LIST_ITEM_5_LINK}
        />
        <ServiceListItem
          title={ServicesPageCaption.SERVICE_LIST_ITEM_6_TITLE}
          description={ServicesPageCaption.SERVICE_LIST_ITEM_6_DESCRIPTION}
          link={ServicesPageCaption.SERVICE_LIST_ITEM_6_LINK}
        />
      </Row>
    </>
  );
};

export default ServicesList;
